<template>
	<div class="barhold">

		<div style="height: 500px; width: 100%;">
			<apexchart :series="apexseries" :options="chartoptionsApex" type="bar" height="100%"></apexchart>
		</div>

		<div style="margin-top: 10px; font-size: 12px; font-style: italic;" v-if="pagedata.sourceNotes && pagedata.sourceNotes.length > 0">
			<p>*{{pagedata.sourceNotes}}</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'CRAInfDialBarVert',
	mixins: [globalMixin],
	components: {

	},
	props: {
		pagedata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			chartoptionsApex: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					type: 'bar',
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						borderRadius: 6,
						borderRadiusApplication: 'end',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: false,
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					max: 100,
					forceNiceScale: false,
					labels: {
						style: {
							fontSize: '16px',
						},
						maxWidth: 300,
					},
				},
				colors: [],
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						if(val === null) return ''
						return val + "%"
					},
					style: {
						colors: ['#000000'],
						fontSize: '15px',
					},
				},
				tooltip: {
					onDatasetHover: {
						highlightDataSeries: true,
					},
					y: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
			},
			apexseries: [],

		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartoptionsApex.colors = this.store.chartColourGroup
			let apexseriesdata = []
			if(this.pagedata.bars) {
				for(let b of this.pagedata.bars) {
					apexseriesdata.push({x: b.label, y: b.dp.pct})
				}
			}
			if(!this.pagedata.doNotSort || this.pagedata.doNotSort !== true) {
				//sort apexseries data largest to smallest by y value
				apexseriesdata.sort(function (a, b) {
					return b.y - a.y;
				})
			}
			this.apexseries = [{name: 'Yes', data: apexseriesdata}]
		},
	},
	watch: {
		pagedata() {
			if(this.pagedata) this.prepData()
		},
	},
	mounted() {
		if(this.pagedata) this.prepData()
	},
	created() {

	},
}
</script>

<style scoped>
.barhold {
	width: 100%;
}
</style>
