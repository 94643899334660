<template>
	<div>
		<div class="pagetitle">
			<div class="pt">
				<slot name="pagenav"></slot>
<!--				<span class="pagetitle-text" v-if="pagedata && pagedata.title">{{pagedata.title}}</span>-->
			</div>
			<slot name="rightside"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'CRAPageTitle',
	mixins: [globalMixin],
	components: {

	},
	props: {
		pagedata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.pagetitle {
	color: #FFF;
	background-color: #E4177D;
	border-radius: 10px;
	padding: 16px 30px;
	margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 1.4;
}
.pagetitle > div {
	display: flex;
	align-items: center;
	gap: 20px;
}
@media screen and (max-width: 500px) {
	.pagetitle {
		display: block;
		text-align: center;
	}
	.pt {
		margin-bottom: 10px;
	}

}
</style>
