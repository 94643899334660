<template>
	<div>
		<div v-if="store.configLoadFailed === true || !store.dashboardString">
			Unable to locate dashboard
		</div>
		<div v-else>
			<D1_CRAInf v-if="store.dashboardString === '1-vadkadad-kdauqerd-f23kladx-8234kdo' && store.configHasLoaded === true"></D1_CRAInf>
			<D1_CRAInf v-if="store.dashboardString === '2-n8daldif-m98ha6d2-zjud73gp-ouy7etd' && store.configHasLoaded === true"></D1_CRAInf>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import D1_CRAInf from "@/components/pages/D1_CRAInf.vue";

export default {
	name: 'MainPage',
	mixins: [globalMixin],
	components: {
		D1_CRAInf

	},
	props: {

	},
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {
		'store.configHasLoaded'() {
			if(this.store.configHasLoaded === true) {
				if(this.store.dashboardConfig.name) {
					document.title = this.store.dashboardConfig.name
				}
			}
		},
	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.params
		if(urlparams.dashboardstring) {
			this.store.dashboardString = urlparams.dashboardstring
			this.getDashboardConfig()
		}
		// let urlqueries = this.$route.query
		// if(urlqueries.iframe && urlqueries.iframe === '1') {
		// 	this.store.inIframe = true
		// }
	},
}
</script>

<style scoped>

</style>
