<template>
	<div>
		<div style="height: 500px; width: 100%;">
			<apexchart :series="apexseries" :options="chartoptionsApex" type="pie" height="100%"></apexchart>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'CRAInfDial3DPie',
	mixins: [globalMixin],
	components: {

	},
	props: {
		pagedata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			chartoptionsApex: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					type: 'pie',
				},
				plotOptions: {
					pie: {

					},
				},
				toolbar: {
					autoSelected: "zoom"
				},
				colors: [],
				labels: [],
				dataLabels: {
					enabled: true,
					// formatter: function (val) {
					// 	return val + "%"
					// },
					style: {
						colors: ['#000000'],
						fontSize: '15px',
					},
					distributed: true,
					formatter: function(val) { //function(val, opts) {
						// const name = opts.w.globals.labels[opts.seriesIndex]
						return [val.toFixed(0) + '%']
					}
				},
				tooltip: {
					onDatasetHover: {
						highlightDataSeries: true,
					},
					y: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
			},
			apexseries: [],

		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartoptionsApex.colors = this.store.chartColourGroup
			let apexdata = []
			let apexlabels = []
			if(this.pagedata.pie) {
				for(let p of this.pagedata.pie.data) {
					apexdata.push(p.dp.pct)
					apexlabels.push(p.name)
				}
				this.apexseries = apexdata
				this.chartoptionsApex.labels = apexlabels
			}
		},
	},
	watch: {
		pagedata() {
			if(this.pagedata) this.prepData()
		},
	},
	mounted() {
		if(this.pagedata) this.prepData()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
