import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts'
// import Highcharts from 'highcharts'
// import vennInit from 'highcharts/modules/venn'
// vennInit(Highcharts)
// import HighchartsMore from 'highcharts/highcharts-more'
// HighchartsMore(Highcharts)
// import solidGaugeInit from 'highcharts/modules/solid-gauge'
// solidGaugeInit(Highcharts)
// import exportingInit from 'highcharts/modules/exporting'
// exportingInit(Highcharts)
// import exportDataInit from 'highcharts/modules/export-data'
// exportDataInit(Highcharts)
// import accesseibilityInit from 'highcharts/modules/accessibility'
// accesseibilityInit(Highcharts)
// import highcharts3d from 'highcharts/highcharts-3d'
// highcharts3d(Highcharts)
// import HighchartsVue from 'highcharts-vue'
import MainPage from "@/components/pages/MainPage.vue";

import '@/includes/style.css'

//routing
const routes = [
	{path: '/:dashboardstring', component: MainPage},
	{path: '/', component: MainPage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router)
app.use(VueApexCharts)
// app.use(HighchartsVue)
app.mount('#app')

//dependencies for package.json
//    "highcharts": "^11.1.0",
//    "highcharts-vue": "^1.4.3",
//	"d3": "^7.8.5",
//	"venn.js": "^0.2.20",