<template>
	<div>
		<div class="" style="height: 250px; width: 100%;">
			<apexchart :series="apexseries" :options="chartoptionsApex" type="radialBar" height="100%"></apexchart>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'VennBox',
	mixins: [globalMixin],
	components: {

	},
	props: {
		vb: {
			type: Object,
			default: null,
		},
		vtype: {
			type: String,
			default: 'either', //only1, only2, either, both
		},
	},
	data() {
		return {
			store: store,

			chartoptionsApex: {
				chart: {
					toolbar: {
						show: false
					},
					animations: {
						enabled: true
					},
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						startAngle: -90,
						endAngle: 90,
						offsetY: 130,
						track: {
							background: '#e7e7e7',
							strokeWidth: '100%',
							margin: 1, // margin is in pixels
							dropShadow: {
								enabled: true,
								blur: 0.5,
								opacity: 1,
							}
						},
						dataLabels: {
							value: {
								show: false,
								fontSize: '30px',
								color: '#000000',
								offsetY: 10,
								fontWeight: 700,
							},
							name: {
								show: false,
								fontSize: '16px',
								color: '#000000',
								offsetY: 50,
								fontWeight: 400,
							},
						},
					},
				},
				toolbar: {
					autoSelected: "zoom"
				},
				colors: [],
				labels: [],
			},
			apexseries: [],

		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartoptionsApex.colors = this.store.chartColourGroup
			let apexdata = []
			let apexlabels = []
			if(this.vb[this.vtype]) {
				apexdata.push(this.vb[this.vtype].dp.pct)
				apexlabels.push(this.vb[this.vtype].label)
			}
			this.apexseries = apexdata
			this.chartoptionsApex.labels = apexlabels
		},
	},
	watch: {
		vb() {
			if(this.vb) this.prepData()
		},
	},
	mounted() {
		if(this.vb) this.prepData()
	},
	created() {

	},
}
</script>

<style scoped>

</style>
