import { reactive } from 'vue'

let apiUrl = 'https://yourwavelength.com/central/api/';
// if(window.location.origin.indexOf("localhost") > -1 || window.location.origin.indexOf("loc.") > -1) {
// 	apiUrl = 'http://wavelength.test/central/api/';
// }

export const store = reactive({
	initialObjectsReceived: false,

	apiUrl: apiUrl,
	inIframe: false,

	showCommas: true,
	showLoader: false,

	demoId: 0,

	chartColourGroup: [
		'#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A','#222222','#D16d77','#226622'
	],
	chartColourGroupGradient: [
		'#7bb7db', '#7295d9', '#6c74d9',
		'#7f6bda', '#9d6bda', '#bf6ddb',
		'#d16dcd', '#d06caa', '#d16d77',
		'#cf6b87', '#d18c6b', '#d8af71',
		'#85de70'
	],
	defaultColors: ['#003f5c','#42d534','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600','#a62166'],
	femaleMaleColors:  ['#e50076','#0055a5'],

	kalert: {
		shown: false,
		message: null,
		type: 'danger',
	},

	dashboardString: null,
	dashboardConfig: {
		id: null,
		name: '',
		urlpart: null,
	},
	dashPageStructure: [],
	configHasLoaded: false,
	configLoadFailed: false,

})