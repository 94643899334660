import axios from "axios";

export const globalMixin = {
	methods: {
		showKalert(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = ob.type;
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertError(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'danger';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertSuccess(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'success';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		numdisplay: function (num, dp = 0) {
			if(!num) return num
			else if(isNaN(num)) return num
			return num.toLocaleString('en-US', {minimumFractionDigits: dp, maximumFractionDigits: dp});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			if(num == 0) return 0
			if(!num) return null
			if(this.store.showCommas) return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
			else return num.toFixed(numberOfDecplc);
		},
		numdisplayNoZeroes(num) {
			if(num === 0) return '';
			else return this.numdisplay(num);
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// let sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		boolToInt: function(boolin) {
			if(boolin === true) return 1;
			return 0;
		},
		trueToYes: function(str) {
			if(str === true) return "Yes";
			return '';
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		convertToUrlName(str) {
			str = str.replaceAll("/","");
			str = str.replaceAll("'","");
			str = str.replaceAll(' ','-').toLowerCase();
			str = str.replaceAll('--','-').toLowerCase();
			return str;
		},
		stripTags(str) {
			return str.replaceAll('<br/>',' ');
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		dateNow() {
			let d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		},
		simplePost(targ, sendob, alertOnError = true) {
			sendob.dashboardString = this.store.dashboardString
			return axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (alertOnError === true && ret.error) {
					this.showKalertError(ret.error)
				}
				return ret
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		getDashboardConfig() {
			if(this.store.dashboardString) {
				let targ = this.store.apiUrl + "dashboard-start"
				let sendob = {}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.dashConfig) {
						this.store.dashboardConfig = ret.dashConfig
						this.store.dashPageStructure = ret.pageStructure
						this.store.configHasLoaded = true
					}
					else {
						this.store.configLoadFailed = true
					}
					this.store.showLoader = false
				}.bind(this));
			}
		},
		getDashboardData(pageId) {
			if(this.store.dashboardString) {
				let targ = this.store.apiUrl + "dashboard-get-data"
				let sendob = {
					pageId: pageId,
					demoId: this.store.demoId,
				}
				return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.store.showLoader = false
					return ret
				}.bind(this));
			}
		},

	},

}