<template>
	<div class="gauge-parents">
		<div v-if="gaugedata && gaugedata[0]" class="gauge-hold">
			<GaugeChart :vb="gaugedata[0]"></GaugeChart>
<!--			<div class="gauge-box-text">-->
<!--				<p class="pct-text">{{gaugedata[0].dp.pct}}%</p>-->
<!--				<p>{{gaugedata[0].label}}</p>-->
<!--			</div>-->
		</div>
		<div v-if="gaugedata && gaugedata[1]" class="gauge-hold">
			<GaugeChart :vb="gaugedata[1]"></GaugeChart>
<!--			<div class="gauge-box-text">-->
<!--				<p class="pct-text">{{gaugedata[1].dp.pct}}%</p>-->
<!--				<p>{{gaugedata[1].label}}</p>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import GaugeChart from "@/components/charts/GaugeChart.vue";
export default {
	name: 'CRAInfDialGauges',
	mixins: [globalMixin],
	components: {
		GaugeChart

	},
	props: {
		pagedata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			gaugedata: [],
		}
	},
	computed: {

	},
	methods: {
		prepData() {
			if(this.pagedata.items) {
				this.gaugedata = this.pagedata.items
			}
		},
	},
	watch: {
		pagedata() {
			if(this.pagedata) this.prepData()
		}
	},
	mounted() {
		if(this.pagedata) this.prepData()
	},
	created() {

	},
}
</script>

<style scoped>
.gauge-parents {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -180px;
	z-index: -1;
	position: relative;
}
.gauge-box-text {
	text-align: center;
}
.pct-text {
	font-size: 35px;
	font-weight: bold;
}
.gauge-hold {
	width: 48%;
}
</style>
