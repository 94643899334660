<template>
	<router-view></router-view>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'App',
	components: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,



		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style>

</style>
