<template>
	<div>
		<div style="height: 500px; width: 100%;">
			<apexchart :series="apexseries" :options="chartoptionsApex" type="bar" height="100%"></apexchart>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'CRAInfDialMultiPctBars',
	mixins: [globalMixin],
	components: {

	},
	props: {
		pagedata: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			store: store,

			chartoptionsApex: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					stacked: true,
					type: 'bar',
				},
				plotOptions: {
					bar: {
						horizontal: true,
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					labels: {
						show: false,
						hideOverlappingLabels: false,
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					max: 100,
					forceNiceScale: false,
					labels: {
						style: {
							fontSize: '16px',
						},
						maxWidth: 300,
					},
				},
				colors: [],
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + "%"
					},
					style: {
						colors: ['#000000'],
						fontSize: '15px',
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
			},
			apexseries: [],

		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartoptionsApex.colors = this.store.chartColourGroup
			this.apexseries = this.pagedata.chartdata
			this.chartoptionsApex.xaxis.categories = this.pagedata.categories
		},
	},
	watch: {
		pagedata() {
			if(this.pagedata) this.prepData()
		},
	},
	mounted() {
		if(this.pagedata) this.prepData()

	},
	created() {

	},
}
</script>

<style scoped>

</style>
