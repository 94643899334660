<template>
	<div>

		<div style="height: 500px; width: 100%;">
			<apexchart :series="apexseries" :options="chartoptionsApex" type="bar" height="100%"></apexchart>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'CRAInfDialMultiColumn',
	mixins: [globalMixin],
	components: {

	},
	props: {
		pagedata: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			store: store,

			chartoptionsApex: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					type: 'bar',
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						borderRadius: 4,
						borderRadiusApplication: 'end',
						columnWidth: '80%',
						distributed: false,
						dataLabels: {
							position: 'top',
						},
					},
				},
				stroke: {
					show: true,
					width: 8,
					colors: ['transparent']
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: false,
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
					labels: {
						show: false,
						style: {
							fontSize: '13px',
						},
					},
				},
				colors: [],
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + "%"
					},
					style: {
						colors: ['#000000'],
						fontSize: '12px',
					},
				},
				tooltip: {
					onDatasetHover: {
						highlightDataSeries: true,
					},
					y: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
			},
			apexseries: [],

		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartoptionsApex.colors = this.store.chartColourGroup
			let cats = []
			let srs = []
			let cnt = 0
			for(let c of this.pagedata.columnCharts) {
				if(cnt === 1) {
					for(let cd of c.data) {
						cats.push(cd.name)
					}
				}
				let s = {name: c.name, data: []}
				for(let cd of c.data) {
					s.data.push(cd.dp.pct)
				}
				srs.push(s)
				cnt++
			}
			this.apexseries = srs
			this.chartoptionsApex.xaxis.categories = cats
		},
	},
	watch: {
		pagedata() {
			if(this.pagedata) this.prepData()
		},
	},
	mounted() {
		if(this.pagedata) this.prepData()

	},
	created() {

	},
}
</script>

<style scoped>

</style>
