<template>
	<div v-if="vb" class="venn-box-parent">
		<div v-for="vtype in myvtypes" :key="vtype" class="venny">
			<div v-if="vb[vtype]" class="venn-box">
				<div class="venn-box-chart">
					<VennBox :vb="vb" :vtype="vtype"></VennBox>
				</div>
				<div class="venn-box-text">
					<p class="pct-text">{{vb[vtype].dp.pct}}%</p>
					<p>{{vb[vtype].label}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import VennBox from "@/components/VennBox.vue";
export default {
	name: 'VennBoxFour',
	mixins: [globalMixin],
	components: {
		VennBox
	},
	props: {
		vb: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,
			vtypes: ['either', 'only1', 'both', 'only2', 'only3'],
		}
	},
	computed: {
		myvtypes() {
			let vt = []
			for(let v of this.vtypes) {
				if(this.vb[v]) vt.push(v)
			}
			return vt
		},
	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.venn-box {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 30px;
	position: relative;
}
.venny {
	margin-bottom: 50px;
	margin-top: -140px;
	position: relative;
}
.venn-box-chart {
	width: 50%;
}
.venn-box-text {
	width: 45%;
}
.pct-text {
	font-size: 35px;
	font-weight: bold;
}
@media screen and (max-width: 1000px) {
	.venn-box-parent {
		display: flex;
		justify-content: space-between;
	}
	.venn-box {
		display: block;
	}
	.venny {
		width: 23%;
	}
	.venn-box-chart, .venn-box-text {
		width: 100%;
	}
	.venn-box-text {
		text-align: center;
	}
}
@media screen and (max-width: 800px) {
	.venn-box-parent {
		flex-wrap: wrap;
	}
	.venny {
		width: 48%;
	}
}
@media screen and (max-width: 500px) {
	.venny {
		width: 100%;
	}
}
</style>
