<template>
	<div>
		<div class="chart-section">
			<div class="venn-section">
				<VennCompareTwo v-if="pagedata.venn" :venndata="pagedata.venn" :venntotals="pagedata.vennTotals"></VennCompareTwo>
			</div>
			<div class="right-section">
				<VennBoxFour v-if="pagedata.vennBoxes" :vb="pagedata.vennBoxes"></VennBoxFour>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import VennCompareTwo from "@/components/charts/VennCompareTwo.vue";
import VennBoxFour from "@/components/charts/VennBoxFour.vue";
export default {
	name: 'CRAInfDialVenns',
	mixins: [globalMixin],
	components: {
		VennBoxFour,
		VennCompareTwo
	},
	props: {
		pagedata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.chart-section {
	display: flex;
	justify-content: space-between;
}
.venn-section {
	width: 60%;
}
.right-section {
	width: 35%;
}
@media screen and (max-width: 1000px) {
	.chart-section {
		flex-wrap: wrap;
	}
	.venn-section, .right-section {
		width: 100%;
	}
	.right-section {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}
}
</style>
