<template>
	<div>
		<div id="canvasholder">
			<canvas id="canvas"></canvas>
		</div>
		<div>
			<p style="font-size: 11px;">Numbers within the circle segments refer to percentage</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import { Chart, LinearScale, Tooltip } from 'chart.js';
import {VennDiagramController, EulerDiagramController, ArcSlice} from 'chartjs-chart-venn';
Chart.register(VennDiagramController, EulerDiagramController, ArcSlice, LinearScale, Tooltip );
export default {
	name: 'ChartJSVenn',
	mixins: [globalMixin],
	components: {

	},
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {
		doVenn() {
			if(this.chartdata && this.chartdata.datasets && this.chartdata.datasets.length > 0) {
				document.getElementById('canvasholder').innerHTML = '<canvas id="canvas"></canvas>'
				let ctx = document.getElementById('canvas').getContext('2d')
				new Chart(ctx, {
					type: EulerDiagramController.id,
					// type: VennDiagramController.id,
					data: this.chartdata,
					options: {
						maintainAspectRatio: false,
						showTooltips: true,
						layout: {
							padding: 20,
							autoPadding: false,
						},
						title: {
							display: false,
							text: 'Venn Diagram Chart',
						},
						plugins: {
							legend: {
								display: false,
							},
							tooltip: {
								callbacks: {
									label: function(context) {
										let di = context.dataIndex
										let dsname = context.dataset.data[di].sets.join(' ∩ ')
										return dsname + ': ' + context.raw.value + '%'
									},
								},
							},
						},
						scales: {
							x: {
								ticks: {
									color: 'black',
									font: {
										size: 18,
									},
								},
							},
							y: {
								ticks: {
									display: false,
								},
							},
						}
					},
				});
			}
		}
	},
	watch: {
		chartdata() {
			this.doVenn()
		},
	},
	mounted() {
		this.doVenn()
	},
	created() {

	},
}
</script>

<style scoped>
#canvasholder {
	width: 100%;
	height: 500px;
}
#canvas {
	width: 100%;
	height: 480px;
}
</style>
